<template>
  <div id="step-payment-type">
    <div class="step-container">
      <div class="step-container__left">
        <!-- 購物車小記 -->
        <div class="step-container__section step-container__section--inline">
          <label for="shopCartotal" class="step-container__label">購物車小記</label>
          <div class="step-container__field step-container__field--inline">
            <p class="m-0">NT$&ensp; {{ personalData.orderAmount }}</p>
          </div>
        </div>
        <!-- 優惠券 -->
        <div class="step-container__section coupon-section">
          <label class="step-container__label coupon-section__label">優惠代碼</label>
          <div class="step-container__field coupon-field">

            <input v-model="couponConfig.code" type="text" class="coupon-field__input" placeholder="請輸入優惠代碼" :readonly="couponConfig.isApply">
            <div v-if="couponConfig.isApply" class="mk-btn coupon-field__btn coupon-field__btn--inactive" @click="cancelCoupon">取消使用</div>
            <div v-else class="mk-btn coupon-field__btn" @click="handleCouponVerify">使用</div>
            <div :class="couponConfig.style">{{ couponConfig.msg }}</div>
          </div>
        </div>
        <!-- 付款方式 -->
        <div class="step-container__section">
          <label for="payMethods" class="step-container__label">付款方式</label>
          <div class="step-container__field">
            <div v-for="(paymentOption, index) in paymentOptions" :key="index" class="form-check mb-1">
              <input class="form-check-input" type="radio" name="payMethods" :id="`payMethods${index}`" :value="paymentOption.code" v-model="personalData.paymentType">
              <label class="form-check-label" :for="`payMethods${index}`">
                <span>{{ paymentOption.name }}&nbsp;</span>
                <span v-if="paymentOption.price != 0" class="markUp">
                  {{ paymentOption.price > 0 ? `+${paymentOption.price}` : paymentOption.price }}
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="step-container__right">
        <!-- 運送方式 -->
        <div class="step-container__section">
          <label for="logistics" class="step-container__label">運送方式</label>
          <div class="step-container__field ">
            <div class="shipping-option-wrapper">
              <div v-for="(shippingOption, index) in shippingOptions" :key="index" class="form-check mb-1">
                <input class="form-check-input" type="radio" name="logistics" :id="`logistics${index}`" :value="shippingOption.code" v-model="personalData.shippingType" @change="handleShippingTypeChange">
                <label class="form-check-label" :for="`logistics${index}`">
                  <span>{{ shippingOption.name }}</span>
                  <span v-if="shippingOption.price != 0" class="markUp">
                    {{ shippingOption.price > 0 ? `+${shippingOption.price}` : shippingOption.price }}
                  </span>
                </label>
              </div>
            </div>
            <p class="description d-none d-lg-block">海外訂單(限重1KG)或遇不可抗拒之因素可能延遲</p>
          </div>
        </div>
      </div>
      <p class="description d-block d-lg-none">海外訂單(限重1KG)或遇不可抗拒之因素可能延遲</p>
    </div>
  </div>
</template>

<script setup>
  import { defineProps, reactive, watch, computed, onMounted, nextTick } from "vue"
  import axios from 'axios';
  import { HandleErrorResponse } from "~/javascripts/utils.js";

  const props = defineProps({
    personalData: {
      type: Object,
      default: () => {}
    },
    paymentOptions: {
      type: Array,
      default: () => []
    },
    shippingOptions: {
      type: Array,
      default: () => []
    },
    landingSale: {
      type: Object,
      default: () => {
        return {
          isVisitor: false,
          slug: '',
        }
      }
    },
  });

  const emit = defineEmits([
    "clearCVSdata",
    "useCoupon"
  ]);
  
  const handleShippingTypeChange = () => {
    emit('clearCVSdata');
  }

  const couponConfig = reactive({
    code:    '',
    isApply: false,
    msg:     '',
    style:   '',
  })

  const validateCouponApiUrl = computed(() => {
    let apiUrl = '/api/carts/validate_coupon'

    // ladingSale為浮動uri
    if (props.landingSale.isVisitor) {
      const slug = props.landingSale.slug;
      apiUrl = `${slug}/validate_coupon`;
    }

    return apiUrl
  })

  watch(() => props.personalData.couponCode, (newV, oldV) => {
    if(props.personalData.couponCode === undefined) {
      // checkout form 沒有coupon
      initCouponVerify();
    } else if (props.personalData.couponCode === '') {
      // 空coupon
    } else if (newV != couponConfig.code){
      // 不是因為子組件因此更改value
      couponConfig.code = newV;
      handleCouponVerify();
    } else if (newV == couponConfig.code){
      // 子組件更改value
    }
  })

  function initCouponVerify() {
    couponConfig.code = '';
    couponConfig.msg = ""
    couponConfig.style = 'coupon-field__msg'
    couponConfig.isApply = false
  }

  async function handleCouponVerify() {
    const params = {
      coupon_code : couponConfig.code,
      order_amount: props.personalData.orderAmount 
    }

    axios.post(validateCouponApiUrl.value, params)
    .then((resp)=>{
      const discount = resp.data.discount;
      couponConfig.msg = "已套用優惠券"
      couponConfig.style = 'coupon-field__msg coupon-field__msg--success'
      couponConfig.isApply = true
      const config = {
        code     : couponConfig.code,
        discount : discount,
      }
      emit('useCoupon', config)
    }).catch((err)=>{
      HandleErrorResponse(err, () => {
        couponConfig.msg = "優惠券無效"
        couponConfig.style = 'coupon-field__msg coupon-field__msg--error'
        const config = {
          code     : "",
          discount : 0,
        }
        emit('useCoupon', config)
      });
    });
  };

  function cancelCoupon() {
    const params = {
      coupon_code : '',
      order_amount: props.personalData.orderAmount 
    }

    axios.post(validateCouponApiUrl.value, params)
    .then((resp)=>{
      // console.log("cant do this")
    }).catch((err)=>{
      HandleErrorResponse(err, () => {
        couponConfig.msg = ""
        couponConfig.style = 'coupon-field__msg'
        couponConfig.isApply = false
        const config = {
          code     : "",
          discount : 0,
        }
        emit('useCoupon', config)
      })
    });
  }
</script>

<style lang="scss" scoped>
  .step-container{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 0 2rem;
    height: 280px;
    &__left, &__right{
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    &__section{
      margin-bottom: 0.6rem;
      height: 100%;
    }
    &__section:last-of-type{
      margin-bottom: 0;
    }
    &__label{
      display: block;
      margin-bottom: 0.6rem;
      font-size: 1.25rem;
    }
    &__field{
      padding: 0.5rem 1rem;
      border: 1px solid rgba(217, 217, 217, 1);
      height: calc(100% - 30px);
      
      .form-check-input:checked { // 覆蓋bootstrp
        background-color: #EE7C26;
        border-color: #EE7C26;
      }
      .shipping-option-wrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: start;
        height: 168px;
      }
    }
   
    .coupon-section {
       position: relative;
      .coupon-field {
        display: flex;
        padding: 0;
        padding-left: 1rem;
        &__input{
          flex: 1;
          outline: none;
          border: none;
          text-align: start;
          height: 100%;
          min-width: 0;
          &::placeholder{
            text-align: start;
            font-size: 14px;
          }
        }
        &__btn{
          flex: 0 0 100px;
          border-radius: 0;
          line-height: 1;
          &--inactive{
            border-color: #acacac;
            background-color: #acacac;
          }
        }
        &__msg{
          position: absolute;
          top: 12px;
          right: 2px;
          font-size: 12px;
          &--success{
            color: #376c5c;
          }
          &--error{
            color: #dc3545;
          }
        }
      }
    }
    .description{
      margin: 2.25rem 0 0;
      text-align: center;
      color: #EE7C26;
      font-size: 0.875rem;
    }
  }

  @media screen and (max-width: 991px){
    .step-container{
      flex-direction: column;
      height: 100%;
      &__left, &__right{
        width: 100%;
      }
      &__section{
        border: 2px solid rgba(217, 217, 217, 1);
        padding:  0.75rem;
        margin-bottom: 0.75rem;
        &--inline{
         display: flex;
         align-items: center;
        }
      }
      &__section:last-of-type{
        margin-bottom: 0.75rem;
      }
      &__label{
        margin: 0;
        font-size: 1.05rem;
      }
      &__field{
        margin-top: 0.5rem;
        padding: 0;
        border: none;
        &--inline{
          margin-top: 0;
          margin-left: 1rem;
          font-size: 1.25rem;
          font-weight: 900;
        }
      }

      .coupon-section{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        height: 48px;
        &__label{
          flex: 0 0 92px;
          padding: 0 0.75rem;
        }
        .coupon-field{
          flex: 1;
          margin-top: 0;
          padding: 0;
          max-width:calc(100% - 92px);
          height: 100%;
          &__input{
            flex: 0 0 calc(100% - 90px);
            padding: 0 0.25rem;
            border-left: 1px solid rgba(217, 217, 217, 1);
            border-radius: 0;
            line-height: 44px;
          }
          &__btn{
            flex: 0 0 90px;
            padding: 0.75rem;
          }
          &__msg{
            top:100%;
          }
        }
      }
      .description{
        margin-top: 0.75rem;
        color: #888
      }
    }
  }
</style>